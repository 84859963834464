/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

#toggle-switch::after {
  content: "";
  @apply h-12 w-12 rounded-full bg-mongo-500 absolute -right-1 -top-2 transform scale-110 transition duration-200;
}

#toggle-switch:checked::after {
  @apply transform scale-110 -translate-x-12;
}
